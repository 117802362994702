'use strict'

angular
  .module 'mundoAuthentication'
  .config ($stateProvider) ->
    $stateProvider
      .state 'authentication',
        url: '/auth'
        templateUrl: 'mundo-authentication/views/mundo-authentication.tpl.html'
        controller: 'MundoAuthenticationCtrl'
        controllerAs: 'mundoAuthentication'
        abstract: true
      .state 'authentication.init',
        url: '/init'
        controller: ($scope) ->
          $scope
            .mundoAuthentication
            .loginRedirection()
        data:
          anonymous: true
          navigation: false
      .state 'authentication.login',
        url: '/login'
        templateUrl: 'mundo-authentication/views/mundo-authentication.login.tpl.html'
        data:
          anonymous: true
          navigation: false
      .state 'authentication.logout',
        url: '/logout'
        controller: ($scope) ->
          $scope
            .mundoAuthentication
            .logout()
        data:
          navigation: false
      .state 'authentication.redirect',
        url: '/redirect/:payload'
        controller: ($scope, $stateParams) ->
          $scope
            .mundoAuthentication
            .parseRedirectPayload $stateParams.payload
        data:
          anonymous: true
          navigation: false
      .state 'user_password_reset',
        url: '/users/password/reset'
        templateUrl: 'mundo-authentication/views/user-password-reset.tpl.html'
        abstract: true
      .state 'user_password_reset.request',
        url: ''
        templateUrl: 'mundo-authentication/views/user-password-reset.request.tpl.html'
        controller: 'UserPasswordResetCtrl'
        controllerAs: 'userPasswordReset'
        data:
          anonymous: true
          navigation: false
      .state 'user_password_reset.confirm',
        url: '/:token'
        templateUrl: 'mundo-authentication/views/user-password-reset.confirm.tpl.html'
        controller: 'UserPasswordResetCtrl'
        controllerAs: 'userPasswordReset'
        data:
          anonymous: true
          navigation: false
      .state 'user_activation',
        url: '/users/activate/:token'
        templateUrl: 'mundo-authentication/views/user-activation.tpl.html'
        controller: 'UserActivationCtrl'
        controllerAs: 'userActivation'
        data:
          anonymous: true
          navigation: false
      .state 'user_account',
        url: '/account'
        templateUrl: 'mundo-authentication/views/user-account.tpl.html'
        controller: 'UserAccountCtrl'
        controllerAs: 'userAccount'
